import React, {
    useState, 
    // useEffect, 
    // useMemo, 
    // useCallback, 
    // useRef
} from 'react';
import {
    useHistory, 
    // useLocation, 
    // Link, 
    // Switch, 
    // Route
} from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import _ from 'lodash';

import { useDataContext } from '../DataProvider';

export default function LoginPane() {
    const {login} = useDataContext();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [state, setState] = useState('input');

    const onClick = async () => {
        setState('login');
        const res = await login(email, password);
        if (res)
            history.goBack();
        setState('error');
    }

    return(
        <div className="LoginPane">
            <div className="controls">
                <h2>Вход в систему</h2>
                <label htmlFor="email">EMail:</label>
                <input type="string" id="email" onChange={(e) => {setEmail(e.target.value); setState('input')}} />
                <label htmlFor="password">Пароль:</label>
                <input type="password" id="password" onChange={(e) => {setPassword(e.target.value); setState('input')}} />
                {state === 'error' ? <span className="error">Неверно введены имя пользователя или пароль</span> : null}
                <button onClick={onClick}>Войти</button>
            </div>
        </div>
    )
}