import React, {useRef, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import L, { DivIcon } from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLeaflet } from 'react-leaflet';

const pointIcons = {};

export function makePointIcon(icon) {
    if (!pointIcons[icon]){
        pointIcons[icon] = new DivIcon({
            html: ReactDOMServer.renderToStaticMarkup(
                <FontAwesomeIcon icon={icon} />
            ),
            className: 'legend-icon'
        });
    }
    return pointIcons[icon];
}

export const MapLayer = React.memo(({items}) => {
    const {map} = useLeaflet();
    const itemsRef = useRef();

    useEffect(() => {
        if (items.length === 0)
            return undefined;

        itemsRef.current = L.layerGroup(items).addTo(map);
        return () => {
            map.removeLayer(itemsRef.current);
        };
    }, [map, items]);
    return null;
}, (prev, next) => prev && next && prev.items === next.items);


require('leaflet.markercluster');

export const MarkerClusterGroup = React.memo((props) => {
    const {map} = useLeaflet();
    const markersRef = useRef();

    useEffect(() => {
        if (props.markers.length === 0)
            return () => null;

        markersRef.current = new L.markerClusterGroup(props);
        markersRef.current.addLayers(props.markers);
        map.addLayer(markersRef.current);
        return () => {
            map.removeLayer(markersRef.current)
        };
    }, [map, props, props.markers]);
    return null;
}, (prev, next) => prev.markers === next.markers);

export function urlQueryToArray(query) {
    const params = new URLSearchParams(query);
    const res = {};
    for (const [k,v] of params)
        res[k] = Number(v);
    return res;
}

export function* map(iter, f) {
    for (const v of iter)
        yield f(v);
}

export function* filter(iter, p) {
    for (const v of iter)
        if (p(v))
            yield v;
}

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      //reader.readAsBinaryString(file);
      reader.readAsArrayBuffer(file);
    })
  }