import React from 'react';
    // ,{useState, useEffect, useMemo, useCallback, useRef} 
import {
    useHistory, 
    // useLocation, 
    // Link, 
    // Switch, 
    // Route
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import _ from 'lodash';

import { useDataContext } from '../DataProvider';

export default
function Nav() {
    const {user, logout} = useDataContext();
    const history = useHistory();

    const onLoginClick = () => {
        if (user){
            logout();
            history.push("/")
        } else {
            history.push("/login");
        }
    }

    return <div className="Nav">
        <button 
            onClick={() => history.push("/")} 
            title='Объекты карты'
        >
            <FontAwesomeIcon icon="globe" size="2x" />
        </button>
        <button 
            onClick={() => history.push("/tariff_zones/list")}
            title='Тарифные зоны'
        >
            <FontAwesomeIcon icon="ruble-sign" size="2x" />
        </button>
        <button 
            onClick={() => history.push("/operators/list")}
            title='Операторы обращения с отходами'
        >
            <FontAwesomeIcon icon="recycle" size="2x" />
        </button>
        <button 
            onClick={() => history.push("/reg_operators/list")}
            title='Региональные операторы'
        >
            <FontAwesomeIcon icon="dumpster-fire" size="2x" />
        </button>
        <button 
            onClick={() => history.push("/reports")}
            title='Отчеты'
        >
            <FontAwesomeIcon icon="file-download" size="2x" />
        </button>
        <button 
            onClick={() => history.push("/dicts")}
            title='Справочники'
        >
            <FontAwesomeIcon icon="book" size="2x" />
        </button>
        {
            user && user.role === 'root' ? (
                <button onClick={() => history.push("/users/list")}>
                    <FontAwesomeIcon icon="users" size="2x" />
                </button>
            ) : null 
        }
        {
            user && user.role === 'root' ? (
                <a target="_blank" href="https://new.zig-zag.org/" rel="noopener noreferrer">
                    <button
                        title='Список пользователей'
                    >
                        <FontAwesomeIcon icon="external-link-alt" size="2x" />
                    </button>
                </a>
            ) : null 
        }

        <button className="login" onClick={onLoginClick}>
            <FontAwesomeIcon icon={user ? "sign-out-alt" : "sign-in-alt"} size="2x" />
        </button>
    </div>
}