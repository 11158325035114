import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import Map from './map';
import {DataProvider} from './DataProvider';

library.add(fas)

const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
whyDidYouRender(React);


function App() {
  return (
    <div className="App">
      <DataProvider>
        <BrowserRouter>
          <Map />
        </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
