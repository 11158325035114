import React, {
    // useState, 
    // useEffect, 
    // useMemo, 
    // useCallback, 
    // useRef
} from 'react';
import {
    useHistory, 
    // useLocation, 
    Link, 
    // Switch, 
    // Route
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default
function DictsPane() {
    const history = useHistory();

    return(
        <div className="DictsPane">
            <div className="head">
                <h2>Справочники</h2>
                <div className="controls">
                    <button className="close" onClick={history.goBack}>
                        <FontAwesomeIcon icon="times" size="lg" />
                    </button>
                </div>
            </div>
            <div className="List">
                <Link to="/operator_types/list">Типы операторов обращения</Link>
                <Link to="/container_types/list">Типы контейнеров</Link>
                <Link to="/surface_types/list">Типы подстилающей поверхности</Link>
                <Link to="/fencing_types/list">Типы ограждений</Link>
                <Link to="/fkko/list">ФККО</Link>
                {/* <Link to="#">Зоны деятельности региональных операторов</Link> */}
            </div>
        </div>
    )
}