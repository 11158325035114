import React, {
    useState, 
    useEffect, 
    // useMemo, 
    // useCallback, 
    // useRef
} from 'react';
import {
    useHistory, 
    // useLocation, 
    // Link, 
    // Switch, 
    // Route
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash';

import { apiRequest } from '../api';

const activities = {
    0: "Образовано",
    1: "Обработано",
    3: "Обезврежено",
    4: "На захоронение",
    5: "Утилизировано"
}

export default function BalancePane() {
    const history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const res = (await apiRequest('GET', "/balance"))["data"];
            setData(_.groupBy(res, 'year'));
        }
        loadData();
    }, []);

    const controls = [];
    for (const year in data) {
        controls.push(<h3 key={year}>{year}</h3>);
        let leg = 0;
        for (const a of data[year]) {
            if (a.activity_type === 0)
                leg = a.avg_leg;
            controls.push(<h4 key={`${year}-${a.activity_type}`}>{activities[a.activity_type]}</h4>);
            controls.push(<div key={`${year}-${a.activity_type}-value`} className="InputValue">{a.amount}</div>)
        }
        controls.push(<h4 key={`${year}-leg`}>{'Плечо доставки'}</h4>);
        controls.push(<div key={`${year}-leg-value`} className="InputValue">{(leg || 0).toFixed(1)}</div>)
    }

    return <div className="BalancePane">
        {!data ? <span>Загрузка данных</span> :
            <>
            <div className="head">
                <h2>Баланс потоков отходов</h2>
                <div className="controls">
                    <button className="close" onClick={history.goBack}>
                        <FontAwesomeIcon icon="times" size="lg" />
                    </button>
                </div>
            </div>
            <div className="ControlsList">
                {controls}
            </div>
            </>
        }
        </div>
}