import React, {
    useState, 
    useEffect, 
    createContext, 
    useContext, 
    useCallback
} from 'react';
// import _ from 'lodash';
import {apiRequest} from './api';


function LoadingIndicator() {
    return(
        <div className="LoadingIndicator">
            <div className="caption">Загрузка данных. Подождите пожалуйста</div>
        </div>
    )
}

export const DataContext = createContext();

export function DataProvider({children}) {
    const [loading, setLoading] = useState(0);
    const [enums, setEnums] = useState({});
    const [meta, setMeta] = useState({});
    const [atd, setAtd] = useState({});
    const [sections, setSections] = useState({});
    const [user, setUser] = useState(null);
    const [adminZones, setAdminZones] = useState({});

    const token = localStorage.getItem('token');

    const getUserInfo = async (token) => {
        const res = (await apiRequest('GET', '/users/me', undefined, {'Authorization': `JWT ${token}`}));
        if(res.result){
            setUser({...res.user, token: token});
        }else{
            localStorage.removeItem('token');
        }
    }

    useEffect(() => {
        if (token) getUserInfo(token);
    }, []);


    const login = async (email, password) => {
        const res = (await apiRequest('POST', '/login', {email: email, password: password}));
        if (res.result) {
            localStorage.setItem('token', res.token);
            setUser({...res.user, token: res.token});
            return true;
        }
        return false;
    }

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    }

    const loadSection = async (name, result) => {
        const res = (await apiRequest('GET', "/"+name, undefined, {'Authorization': `JWT ${user && user.token}`}))["data"];
        const data = new Map();
        if (res){
            for (const v of res){
                data.set(v.id, v);
            }
        }
        result[name] = data;

        if (name === "operators") {
            result.reg_operators = new Map();
            data.forEach(v => {
                if(v.operator_type === 0){result.reg_operators.set(v.id, v)};
            });
        }
    }

    const reloadSection = async (name) => {
        const res = {};
        await loadSection(name, res);
        setSections(s => ({...s, [name]: res[name]}));
        if (name === "operators"){
            setSections(s => ({...s, reg_operators: res.reg_operators}));
        }
    }

    const loadUsers = async (token, result) => {
        if (token) {
            const res = (await apiRequest('GET', '/users', undefined, {'Authorization': `JWT ${token}`}))["data"];
            result['users'] = res;
        }
    }

    const afterLoadUsers = async () => {
        let token = localStorage.getItem('token')
        if (token) {
            const res = (await apiRequest('GET', '/users', undefined, {'Authorization': `JWT ${token}`}))["data"];
            let res_sec = Object.assign({}, sections, { 'users': res })
            setSections(res_sec);
        }
    }

    useEffect(() => {
        const loadEnums = async () => {
            const enums = (await apiRequest('GET', `/enums`))["data"];
            setEnums(enums);
        };
        const loadMeta = async () => {
            const res = await apiRequest('GET', `/meta`);
            setMeta(res);
        };

        const run = async () => {
            setLoading(l => l+1)
            const newSections = {}
            await Promise.all([
                loadEnums(),
                loadMeta(),
                loadSection('admin_boundaries', newSections),
                loadSection('dump_points', newSections),
                loadSection('operators', newSections),
                loadSection('container_types', newSections),
                loadSection('sources/organizational', newSections),
                loadSection('sources/residential', newSections),
                loadSection('facilities', newSections),
                loadSection('aggregated_sources', newSections),
                loadSection('waste_traffic', newSections),
                loadSection('tariff_zones', newSections),
                loadSection('operator_types', newSections),
                loadSection('container_types', newSections),
                loadSection('surface_types', newSections),
                loadSection('fencing_types', newSections),
                loadSection('fkko', newSections)
            ]);
            // loadUsers(token, newSections)
            

            newSections.admin_boundaries_6 = new Map();
            newSections.admin_boundaries.forEach(v => {
                if(v.level === 6){
                    newSections.admin_boundaries_6.set(v.id, v);
                }
            });
            newSections.sources = new Map([...newSections['sources/organizational'], ...newSections['sources/residential']]);
            setSections(newSections);

            const res = (await apiRequest('GET', `/admin_boundaries/at_level/6`))["data"];
            setAtd(atd => ({...atd, 6: res || []}));

            const adminZones = {};
            for (const z of res){
                adminZones[z.id] = z;
            }
            setAdminZones(adminZones);

            setLoading(l => l-1);
        };
        run();
    }, []);


    const getATD = useCallback((level) => {
        if (atd[level]){
            return atd[level];
        }
    }, [atd]);

    const getAtdItem = useCallback((id) => {
        for (let k in atd) {
            if (!atd[k]){
                return null;
            }
            for (let i of atd[k]) {
                if (i.id === id){
                    return i;
                }
            }
        }
        return null; 
    }, [atd]);

    const getZoneName = useCallback((id) => {
        const z = adminZones && adminZones[id];
        const op = z && sections.reg_operators && sections.reg_operators.get(z.reg_operator_id);
        return (op && op.name) || "??";
    }, [adminZones, sections.reg_operators])


    return(
        <DataContext.Provider 
            value={{
                enums, meta,
                getATD, getAtdItem,
                sections,
                user, login, logout,
                reloadSection,
                getZoneName,
                afterLoadUsers
            }}
        >
            {children}
            {loading > 0 ? <LoadingIndicator /> : null}
        </DataContext.Provider>
    )
}

export const useDataContext = () => useContext(DataContext);

// mapObjects,