import React, {
    useState, 
    useEffect, 
    // useMemo, 
    // useCallback, 
    // useRef
} from 'react';
import {
    useHistory, 
    // useLocation, 
    // Link, 
    Switch, 
    Route
} from 'react-router-dom';
import { 
    Map as LeafletMap, 
    TileLayer, 
    GeoJSON, 
    // Marker, 
    // Popup, 
    // useLeaflet 
} from 'react-leaflet';
import L from 'leaflet';
import _ from 'lodash';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ReactDOMServer from 'react-dom/server';

import { useDataContext } from '../DataProvider';
import ObjectsPane from './ObjectsPane';
import EditPane from './EditPane';
import Nav from './Nav';
import LoginPane from './LoginPane';
import { MapLayer, filter, map } from '../util';
import ListPane from './ListPane';
import BalancePane from './BalancePane';
import DictsPane from './DictsPane';
import ReportsPane from './ReportsPane';
import {BASE} from '../api';


const zoneColors = ['#2980b9','#c0392b','#009933','#FFC300','#FF5733','#C70039'];

const initBbox = [[180, 90], [-180, -90]];

function calcBbox(points) {
    let [[minLon, minLat], [maxLon, maxLat]] = initBbox;
    points.forEach(p => {
        minLat = Math.min(minLat, p[0]);
        minLon = Math.min(minLon, p[1]);
        maxLat = Math.max(maxLat, p[0]);
        maxLon = Math.max(maxLon, p[1]);
    });
    return [[minLon, minLat], [maxLon, maxLat]];
}

let maxMapBounds;
let initMapCenter;
if (BASE === 'kostroma') {
    maxMapBounds = [[57.2721688, 40.3901028],[59.6236037, 47.6395146]];
    initMapCenter = [57.77, 41.80];
}
else if (BASE === 'vladimir') {
    maxMapBounds = [[55.31, 37.71], [56.74, 41.5]];
    initMapCenter = [56.18, 39.674];
}


const RegOperatorZone = React.memo(({k, zone}) => (
    <GeoJSON
        data={zone}
        style={{
            weight: 0.4,
            color: 'transparent',
            fillColor: zoneColors[k]
        }}
    />
));

const RegOperatorsLegend = React.memo(({operators}) => (
    <div className="RegOperatorsTitle">
        <p>Зоны деятельности региональных операторов</p>
        <div className="RegOperatorsLegend">
            {operators && [...operators].map(([,op],k) => (
                <React.Fragment key={`$op-${k}`}>
                    <div className="color" style={{background: zoneColors[k]}}></div>
                    <div className="name">{op.name}</div>
                </React.Fragment>
            ))}
        </div>
    </div>
))

const ZigZagLabel = () => (
    <div className='ZigZagLabel'>
        <a
            href="//zig-zag.org"
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src="./made_in_zig-zag.png"
                alt="Zig-Zag Logo"
            />
        </a>
    </div>
)

const adminCenterIcon = L.divIcon({
    html: '&#9733;',
    className: 'legend-icon'
});

export default function Map() {
    const [center, setCenter] = useState(initMapCenter);
    const [zoom, setZoom] = useState(11);
    const [atdLevel, setAtdLevel] = useState(6);
    const [atdLayer, setAtdLayer] = useState([]);
    const [previewRegion, setPreviewRegion] = useState(null);
    const {getATD, sections} = useDataContext();
    const [selectedRegion, setSelectedRegion] = useState(null);
    const history = useHistory();

    const getSelectedRegionBounds = () => {
        if (!selectedRegion)
            return null;
        return calcBbox(_.flatten(_.flatten(selectedRegion.geometry.coordinates)));
    }

    const atdLayerMouseOver = (e) => {
        setPreviewRegion(e.layer.options.info);
    }

    const atdLayerMouseOut = (e) => {
        setPreviewRegion(null);
    }

    const atdLayerMouseClick = (e) => {
        setSelectedRegion(e.layer.options.info);
        if (history.location.pathname.startsWith("/admin_boundaries"))
            history.replace(`/admin_boundaries/${e.layer.options.info.id}`);
        else
            history.push(`/admin_boundaries/${e.layer.options.info.id}`);
    }

    const AtdLayer = React.memo(({atdLevel, getATD}) => {
        const atd = getATD(atdLevel);
        if (!atd)
            return null;

        return atd.map((b) =>
            <GeoJSON
                key={b.id}
                data={b.geometry}
                onMouseOver={atdLayerMouseOver}
                onMouseOut={atdLayerMouseOut}
                onClick={atdLayerMouseClick}
                info={b}
                onEachFeature={(feature, layer) => {
                    layer.on('mouseover', function () {
                        this.setStyle({
                            color: 'red'
                        });
                    });
                    layer.on('mouseout', function () {
                        this.setStyle({
                            color: 'royalblue'
                        });
                    });
                    layer.bindTooltip(b.name);
                }}
                style={{
                    weight: 0.4,
                    color: 'royalblue',
                    fillColor: 'transparent'
                }}
                pane='atd'
            />
        );
    });

    const AdminCenters = React.memo(({atdLevel}) => {
        if (sections.admin_boundaries)
            return <MapLayer items={[...map(filter(sections.admin_boundaries, ([k,p]) => p.level === atdLevel && p.lat_lon),
                                    ([k,p]) => L.marker(p.lat_lon, {icon: adminCenterIcon}))]}
        />
    })

    useEffect(() => {
        try {
            var div = L.DomUtil.get('panes');
            L.DomEvent.disableClickPropagation(div);
            L.DomEvent.on(div, 'mousewheel', L.DomEvent.stopPropagation);
            L.DomEvent.on(div, 'mousedown', L.DomEvent.stopPropagation);
        }
        catch {}
    });

    const onMap = (ref) => {
        if (!ref)
            return;
        if (!ref.leafletElement.getPane('decor'))
            ref.leafletElement.createPane('decor');
        if (!ref.leafletElement.getPane('atd'))
            ref.leafletElement.createPane('atd');
    };

    return(
        <div className="map">
            <Nav />
            <LeafletMap
                center={center}
                zoom={zoom}
                zoomControl={false}
                maxZoom={18}
                bounds={getSelectedRegionBounds() || maxMapBounds}
                minZoom={0}
                maxBoundsViscosity={1.0}
                ref={onMap}
            >
                <TileLayer
                    className="tiles"
                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    useCache={true}
                />
                {sections.reg_operators && [...sections.reg_operators].map(([,op],k) => (
                    <RegOperatorZone key={`operator_${op.id}`} k={k} zone={op.zone} />
                ))}
                <RegOperatorsLegend operators={sections.reg_operators} />
                <AtdLayer atdLevel={atdLevel} getATD={getATD} />
                <AdminCenters atdLevel={atdLevel}  />
                <ZigZagLabel />

                <div className="panes" id="panes">
                    <Switch>
                        <Route exact path="/" render={() => {
                            return <ObjectsPane
                                region={previewRegion}
                            />}
                        }/>
                        <Route path="/login">
                            <LoginPane />
                        </Route>
                        <Route path="/balance">
                            <BalancePane />
                        </Route>
                        <Route path="/dicts">
                            <DictsPane />
                        </Route>
                        <Route path="/reports">
                            <ReportsPane />
                        </Route>
                        <Route 
                            path="/:type+/list" 
                            render={({match, location, history}) => {
                                return <ListPane 
                                            location={location} 
                                            history={history} 
                                            section={match.params.type}
                                        />
                        }}/>
                        <Route path="/:type+/:id" render={({match, location, history}) => {
                            const type = match.params.type.split("/")[0];
                            const obj = _.find(sections[type], ['id', Number(match.params.id)]);
                            return(
                                <EditPane
                                    key={location.pathname}
                                    location={location}
                                    history={history}
                                    section={match.params.type}
                                    obj={obj}
                                    id={match.params.id}
                                />
                            )
                        }}>
                        </Route>
                    </Switch>
                </div>
            </LeafletMap>
        </div>
    )
}
//Map.whyDidYouRender = true;