import _ from 'lodash';
import {API_ROOT, BASE} from './api.js';

export
const mapObjects = {
    sources: {
        name: "Источники", 
        url: "/sources",
        subtypesEnum: "source_type",
        infoMap: "sources",
        infoKey: "source_type",
        subtypesInfo: {
            1: {"icon": "building", url: "/sources/residential", name: "МКД"},
            2: {"icon": "home", url: "/sources/residential", name: "ИЖС"},
            3: {"icon": "university", url: "/sources/organizational", name: "Прочие объекты кап. строительства"}
        }
    },
    dumpPoints: {
        name: "Места накопления",
        url: "/dump_points",
        subtypesEnum: "dump_type",
        infoMap: "dump_points",
        infoKey: "dump_type",
        subtypesInfo: {
            0: {"icon": "dumpster", url: "/dump_points/container_grounds", name: "Контейнерные площадки"}
        }
    },
    facilities: {
        name: "Объекты обращения",
        url: "/facilities",
        subtypesEnum: "facility_type",
        infoKey: "facility_type",
        infoMap: "facilities",
        subtypesInfo: {
            1: {"icon": "cog", url: "/facilities", name: "Объект обработки", listName: "Обработка"},
            3: {"icon": "biohazard", url: "/facilities", name: "Объект обезвреживания", listName: "Обезвреживание"},
            4: {"icon": "boxes", url: "/facilities", name: "Объект захоронения", listName: "Захоронение"},
            5: {"icon": "recycle", url: "/facilities", name: "Объект утилизации", listName: "Утилизация"}
        }
    }
}

export
const sectionInfo = {
    "tariff_zones": {
        name: "Тарифные зоны"
    },
    "operators": {
        name: "Оператор обращения с отходами"
    },
    "reg_operators": {
        name: "Региональные операторы",
        listPaneFields: [
            {title: "", name: ""}
        ],
        apiSection: "operators"
    },
    "users": {
        name: "Список пользователей"
    },
    "admin_boundaries": {
        name: "Административные районы",
    },
    "sources/residential": {
        name: "Источники: жилые дома",
        listName: (obj, ctx) => `${obj.id} - ${ctx.getZoneName(obj.admin_id)} - ${ctx.enums['source_type'][obj.source_type]} - ${obj.address}`,
        report: `${API_ROOT}/reports/residential`,
    },
    "sources/organizational": {
        name: "Источники: организации",
        listName: (obj, ctx) => `${obj.id} - ${ctx.getZoneName(obj.admin_id)} - ЮЛ - ${obj.name} - ${obj.address}`,
        report: `${API_ROOT}/reports/organizational`,
    },
    "dump_points/container_grounds": {
        name: "Контейнерные площадки",
        parentSection: "dump_points",
        filter: (s) => _.filter(s, ['dump_type', 0]),
        listName: (obj, ctx) => {
            return `${obj.id} - ${ctx.getZoneName(obj.admin_id)} - КП - ${obj.name}`
        },
        report: `${API_ROOT}/reports/container_grounds`,
        search: ["operator_id", "separate_collection"]
    },
    "dump_points/containerless_collection": {
        name: "Вывоз по графику",
        parentSection: "dump_points",
        filter: (s) => _.filter(s, ['dump_type', 2]),
        report: `${API_ROOT}/reports/containerless_collection`,
    },
    "dump_points/illegal_dumps": {
        name: "Несанкционированные свалки",
        parentSection: "dump_points",
        filter: (s) => _.filter(s, ['dump_type', 3]),
        report: `${API_ROOT}/reports/illegal_dumps`,
    },
    "facilities": {
        name: "Объекты обращения",
        listName: (obj, ctx) => {
            if (BASE === 'vladimir')
                return `${obj.id} - ${ctx.getZoneName(obj.admin_id)} - ${ctx.enums['facility_type'][obj.facility_type]} - ${obj.name}`

            const op = (ctx.sections['operators'] && ctx.sections['operators'].get(obj.operator_id));
            return `${obj.id} - ${op && op.name || "??"} - ${ctx.enums['facility_type'][obj.facility_type]} - ${obj.name}`
        },
        report: `${API_ROOT}/reports/facilities`,
    },
    "container_types": {
        name: "Типы контейнеров",
        listName: (obj,ctx) => `${obj.id} - ${obj.name} (${obj.waste_weight || 0} кг, ${obj.waste_volume} м³)`
    },
    "operator_types": {
        name: "Типы операторов обращения",
        listName: (obj,ctx) => `${obj.id} - ${obj.name}`
    },
    "surface_types": {
        name: "Типы подстилающей поверхности",
        listName: (obj,ctx) => `${obj.id} - ${obj.name}`
    },
    "fencing_types": {
        name: "Типы ограждений",
        listName: (obj,ctx) => `${obj.id} - ${obj.name}`
    },
    "fkko": {
        name: "Виды отходов по ФККО",
        listName: (obj,ctx) => `${obj.id} - ${obj.code} - ${obj.name}`,
        lookupName: (obj,ctx) => `${obj.code} - ${obj.name}`
    }
}

export
function getObjSubtypeInfo(obj) {
    if (obj.source_type !== undefined)
        return mapObjects.sources.subtypesInfo[String(obj.source_type)] || {};
    if (obj.dump_type !== undefined)
        return mapObjects.dumpPoints.subtypesInfo[String(obj.dump_type)] || {};
    if (obj.facility_type !== undefined)
        return mapObjects.facilities.subtypesInfo[String(obj.facility_type)] || {};
    return {}
    // return null;
}


        // 2: {"icon": "hourglass-half", url: "/dump_points/containerless_collection", name: "вывоз по графику"},
        //3: {"icon": "poop", url: "/dump_points/illegal_dumps", name: "несанкционированная свалка"}
        //parentSection: "sources/residential",
        //listFields: ['address'],
        //parentSection: "sources/organizational",
        //filter: (s) => _.filter(s, ['source_type', 3]),
        //filter: (s) => _.filter(s, (v) => v.source_type && (v.source_type == 1 || v.source_type == 2)),
