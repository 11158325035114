import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {apiRequest, decodeError} from '../api';
import { useDataContext } from '../DataProvider';
import { ControlsList } from '../edits/SimpleEdit';
import {urlQueryToArray} from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObjectsPane from './ObjectsPane';
import _ from 'lodash';
import { TrafficLines } from './TrafficLines';
import {sectionInfo} from '../metadata';

const EditPane = React.memo(({location, history, section, id}) => {
    const [data, setData] = useState(null);
    const [edit, setEdit] = useState(id === "new");
    const [changes, setChanges] = useState(urlQueryToArray(location.search));
    const {user, meta, reloadSection, getAtdItem} = useDataContext();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!meta.sections || !meta.sections[section])
            return;

        if (id === "new") {
            const newValue = {};
            for (let f of meta.sections[section] || [])
                newValue[f.name] = null;
            setData(newValue);
            return;
        }

        const run = async () => {
            const res = (await apiRequest('GET', `/${sectionInfo[section].apiSection || section}/${id}`, undefined, {'Authorization': `JWT ${user && user.token}`}))["data"];
            setData(res);
        };
        run();
    }, [setData, meta, id, section, user]);

    const save = async () => {
        setError(null);
        if (id === "new") {
            const res = (await apiRequest('POST', `/${sectionInfo[section].apiSection || section}`, changes, {'Authorization': `JWT ${user.token}`}));
            if (res.result) {
                setEdit(false);
                await reloadSection(section.split("/")[0]);
                setChanges({});
                history.replace(`/${section}/${res.id}`);
            }
            else {
                setError(decodeError(res));
            }
        }
        else {
            const res = await apiRequest('PUT', `/${sectionInfo[section].apiSection || section}/${id}`, changes, {'Authorization': `JWT ${user.token}`});
            if (res.result) {
                setEdit(false);
                await reloadSection((sectionInfo[section].apiSection || section).split("/")[0]);
                setChanges({});
                const data = (await apiRequest('GET', `/${sectionInfo[section].apiSection || section}/${id}`, undefined, {'Authorization': `JWT ${user && user.token}`}))["data"];
                setData(data);
            }
            else {
                setError(decodeError(res));
            }
        }
    }

    const del = async () => {
        setEdit(false);
        setError(null);
        const res = await apiRequest('DELETE', `/${sectionInfo[section].apiSection || section}/${id}`, undefined, {'Authorization': `JWT ${user.token}`});
        if (res.result) {
            await reloadSection((sectionInfo[section].apiSection || section).split("/")[0]);
            setChanges({});
            history.goBack();
        }
        else {
            setError(decodeError(res));
        }
    }

    const startEdit = () => {
        setEdit(true);
    }

    const onChange = useCallback((field, value) => {
        setChanges(changes => ({...changes, [field]: value}));
    }, [setChanges]);

    const values = useMemo(() => ({...data, ...changes}), [data, changes]);

    let region = null;
    if (section === 'admin_boundaries') {
        region = getAtdItem(Number(id));
    }

    return <div className="EditPane">
        {(!data || !meta.sections || !meta.sections[section]) ? <span>Загрузка данных</span> :
            <>
            <div className="head">
                <h2>{edit ? "Редактирование": "Просмотр"} объекта</h2>
                <div className="controls">
                    {user &&
                        <>
                        {_.get(user, ['rights', `/${sectionInfo[section].apiSection || section}`, 'delete']) &&
                            <button className="delete" hidden={id === "new" || !edit} disabled={id === "new" || !edit} onClick={del}>
                                <FontAwesomeIcon icon="trash" size="lg" />
                            </button>
                        }
                        {_.get(user, ['rights', `/${sectionInfo[section].apiSection || section}`, 'put']) &&
                            <>
                            <button className="edit" hidden={edit} disabled={edit} onClick={startEdit}>
                                <FontAwesomeIcon icon="pen" size="lg" />
                            </button>
                            <button className="save" hidden={!edit} disabled={!edit} onClick={save}>
                                <FontAwesomeIcon icon="save" size="lg" />
                            </button>
                            </>
                        }
                        </>
                    }
                    <button className="close" onClick={history.goBack}>
                        <FontAwesomeIcon icon="times" size="lg" />
                    </button>
                </div>
                {error ? <div className="error">{error}</div> : null}
            </div>
            <ControlsList
                edit={edit}
                meta={meta.sections[section]}
                values={values}
                onChange={onChange}
            />
            {region &&
                <ObjectsPane
                    region={region}
                />}
            </>}
            <TrafficLines section={section} item={values} />
    </div>
})

export default EditPane;

// export default React.memo(EditPane, (prev, next) => {
//     return prev.obj && next.obj && prev.obj === next.obj;
// });