import React, {
    // useEffect, 
    // useRef, 
    // useState, 
    // useMemo, 
    useCallback
} from 'react';
import { useDataContext } from '../DataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
    API_ROOT, 
    // apiRequest
} from '../api';
import {readFileAsync} from '../util';

export default
function ImportControl({section}) {
    const {user, loading} = useDataContext();

    const _importExcel = useCallback((e) => {
            const files = e.target.files;
            if (files.length === 0)
                return;

            const run = async (f) => {
                const content = await readFileAsync(f);
                //const formData = new FormData();
                //formData.append('file', f);
                let res = await fetch(`${API_ROOT}/${section}/import`, {
                    method: 'POST',
                    headers: {
                        'Accept': '*',
                        'Authorization': `JWT ${user.token}`,
                        'Content-Type': 'application/octet-stream'
                    },
                    body: content
                });
                res = await res.json();
                if (res.result && res.errors.length === 0) {
                    window.alert("Импорт завершён успешно");
                    return;
                }
                window.alert(JSON.stringify(res));
            }

            run(files[0]);
    }, [section, user]);

    if (loading || !_.get(user, ['rights', '/'+section+"/import", 'post']))
        return null;

    return(
        <div className="ImportControl">
            <button  onClick={() => window.open(`${API_ROOT}/templates/${section}.xlsx`)}>
                <FontAwesomeIcon icon="file-excel" />
            </button>
            <button  onClick={() => document.getElementById('input-file').click()}>
                <FontAwesomeIcon icon="file-upload" />
            </button>
            <input type="file" id="input-file" style={{display: 'none'}} onChange={_importExcel}  />
        </div>
    )
}