import React, { 
    // useMemo, 
    // useState, 
    // useCallback 
} from 'react';
import {
    useHistory, 
    // Link
} from 'react-router-dom';

import { useDataContext } from "../DataProvider";
import { sectionInfo } from '../metadata';
import { 
    makePointIcon, 
    MapLayer, 
    // MarkerClusterGroup, 
    // map as fmap, 
    filter 
} from '../util';
import { getObjSubtypeInfo } from '../metadata';
import L from 'leaflet';

function trafficLine(from, to) {
    return L.polyline([from, to], {
        className: "traffic-line"
    })
}

function marker(p, history) {
    console.log(getObjSubtypeInfo(p))
    return L.marker(p.lat_lon, {
                    icon: makePointIcon(getObjSubtypeInfo(p).icon)
                })
                .bindTooltip(
                    `<div class="marker-tooltip">${p.address}</div>`
                )
                .on('click', () => history.push(`${getObjSubtypeInfo(p).url}/${p.id}`))
}

function traceTrafficFromDump(dump, lines, markers, sections, history) {
    const traffic = [...filter(sections.waste_traffic, ([k,t]) => t.from_admin_id === dump.admin_id)];
    const facilities = [];
    if (sections.facilities)
        for (const [k,t] of traffic) {
            if (!t.to_facility_id)
                continue;

            const f = sections.facilities.get(t.to_facility_id);
            if (!f || !f.lat_lon)
                continue;

            lines.push(trafficLine(dump.lat_lon, f.lat_lon));
            markers.push(marker(f, history));
            facilities.push(f);
        }
    for (const f of facilities) {
        const traffic = [...filter(sections.waste_traffic, ([k,t]) => t.from_facility_id === f.id)];
        for (const [k,t] of traffic) {
            if (!t.to_facility_id)
                continue;

            const f2 = sections.facilities.get(t.to_facility_id);
            if (!f2 || !f2.lat_lon)
                continue;

            lines.push(trafficLine(f.lat_lon, f2.lat_lon));
            markers.push(marker(f2, history));
        }
    }
}

function SourceTrafficLine({item}) {
    const {sections, loading} = useDataContext();
    const history = useHistory();

    if (!item.lat_lon || loading)
        return null;

    const lines = [];
    const markers = [];

    const dump = sections.dump_points && sections.dump_points.get(item.dump_point_id);
    if (!dump || !dump.lat_lon)
        return null;

    lines.push(trafficLine(item.lat_lon, dump.lat_lon));
    markers.push(marker(dump, history));
    traceTrafficFromDump(dump, lines, markers, sections, history);

    return <>
        <MapLayer items={lines} />
        <MapLayer items={markers} />
    </>
}

function DumpTrafficLines({item}) {
    const {sections} = useDataContext();
    const history = useHistory();

    if (!item.lat_lon || !sections.sources)
        return null;

    const lines = [];
    const markers = [];

    for (const [,v] of sections.sources) {
        if (!v.lat_lon || v.dump_point_id !== item.id)
            continue;

        lines.push(trafficLine(v.lat_lon, item.lat_lon));
        markers.push(marker(v, history));
    }
    traceTrafficFromDump(item, lines, markers, sections, history);

    return <>
        <MapLayer items={lines} />
        <MapLayer items={markers} />
    </>
}

function FacilitiesTrafficLines({item}) {
    const {sections} = useDataContext();
    const history = useHistory();

    if (!item.lat_lon || !sections.sources)
        return null;

    const lines = [];
    const markers = [];
    const facilities = [];

    for (const [,t] of sections.waste_traffic) {
        if (t.from_facility_id === item.id && t.to_facility_id) {
            const f2 = sections.facilities.get(t.to_facility_id);
            if (!f2 || !f2.lat_lon)
                continue;

            lines.push(trafficLine(item.lat_lon, f2.lat_lon));
            markers.push(marker(f2, history));
        }
        if (t.to_facility_id === item.id && t.from_facility_id) {
            const f2 = sections.facilities.get(t.from_facility_id);
            if (!f2 || !f2.lat_lon)
                continue;

            lines.push(trafficLine(f2.lat_lon, item.lat_lon));
            markers.push(marker(f2, history));
            facilities.push(f2);
        }
        if (t.to_facility_id === item.id && t.from_admin_id) {
            const f2 = sections.admin_boundaries.get(t.from_admin_id);
            if (!f2 || !f2.lat_lon)
                continue;

            lines.push(trafficLine(f2.lat_lon, item.lat_lon));
        }
    }

    for (const [,t] of sections.waste_traffic) {
        if (!t.from_admin_id)
            continue;

        let f2 = null;
        for (const f of facilities)
            if (t.to_facility_id === f.id) {
                f2 = f;
                break;
            }
        if (!f2)
            continue;

        const adm = sections.admin_boundaries.get(t.from_admin_id);
        lines.push(trafficLine(adm.lat_lon, f2.lat_lon));
    }

    return <>
        <MapLayer items={lines} />
        <MapLayer items={markers} />
    </>
}

export
const TrafficLines = React.memo(({section, item}) => {
    if (!item.id)
        return null;

    section = sectionInfo[section].parentSection || section;
    if (section.startsWith('sources'))
        return <SourceTrafficLine item={item} />
    if (section === 'dump_points')
        return <DumpTrafficLines item={item} />
    if (section === 'facilities')
        return <FacilitiesTrafficLines item={item} />

    return null;
}, (prev,next) => prev.section === next.section && prev.item.id === next.item.id);