import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import ReactDOMServer from 'react-dom/server';
import { PropTypes } from 'prop-types';
import { useDataContext } from '../DataProvider';
import {
    // useHistory, 
    useLocation
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useLeaflet, 
    // Marker
} from 'react-leaflet';
import L, {DivIcon} from 'leaflet';
import 'leaflet-draw';
import _ from 'lodash';
import {apiRequest} from '../api';
import {map} from '../util';
import { getObjSubtypeInfo, sectionInfo } from '../metadata';


class SimpleInput extends React.PureComponent {
    static propTypes = {
        type: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeHolder: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        onChangeValue: PropTypes.func,
        toView: PropTypes.func,
        fromView: PropTypes.func,
        disabled: PropTypes.bool,
        autoComplete: PropTypes.string,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            value: (props.toView ? props.toView(props.value) : props.value) || ''
        }
    }

    _onChange = (e) => {
        try {
            this.props.onChangeValue
                && this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
            this.setState({value: e.target.value, error: null});
        }
        catch (ex) {
            this.setState({error: ex, value: e.target.value})
        }
    }

    render() {
        if (this.props.disabled)
            return <div className="InputValue">{(this.props.toView ? this.props.toView(this.props.value) : this.props.value) || ''}</div>

        return (
            <input
                autoComplete={this.props.autoComplete}
                className={"Input"}
                id={this.props.name}
                key={this.props.name}
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
                type={this.props.type}
                name={this.props.name}
                placeholder={this.props.placeHolder}
                value={this.state.value}
                onChange={this._onChange}
                disabled={this.props.disabled}
            />
        );
    }
}


export
const TextInput = (props) => (
    <SimpleInput
        fromView={String}
        type='text'
        {...props} />
);

export
const DateInput = (props) => (
  <SimpleInput
    type='date'
    {...props}
  />
);

// export
// const TimeInput = (props) => (
//   <SimpleInput
//     toView={decimalHoursToTime}
//     fromView={timeToDecimalHours}
//     type='time'
//     {...props}
//   />
// );

export
const NumberInput = (props) => (
    <SimpleInput
        toView={(val) => (val === null || val === undefined) ? '' : String(val)}
        fromView={(val) => {
            if (val.length) {
                let num = Number(val.replace(',', '.'));
                num = props && isFinite(props.min) && num < props.min ? props.min : num;
                num = props && isFinite(props.max) && num > props.max ? props.min : num;
                return num;
            }
            return null;
        } }
        type='number'
        {...props}
    />
);

// export
// const RangeInput = (props) => (
//   <div className={b('Range')}>
//     <SimpleInput
//       toView={(val) => val === null ? '' : String(val)}
//       fromView={(val) => val.length ? Number(val) : null }
//       type='range'
//       {...props}
//     />
//     <div className={b('RangeValue')}>{props.value}</div>
//   </div>
// );
// RangeInput.fieldName = 'range';

// export
// const PasswordInput = (props) => (
//   <SimpleInput
//     fromView={String}
//     type='password'
//     {...props}
//   />
// );
// PasswordInput.fieldName = 'password';

// export
// const EmailInput = (props) => (
//   <SimpleInput
//     fromView={(val) => val.length ? String(val) : null}
//     type='email'
//     {...props}
//   />
// );
// EmailInput.fieldName = 'email';


export
class MultilineInput extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.any,
        onChangeValue: PropTypes.func,
        toView: PropTypes.func,
        fromView: PropTypes.func,
        disabled: PropTypes.bool,
        style: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            value: (props.toView ? props.toView(props.value) : props.value) || ''
        }
    }

    _onChange = (e) => {
        try {
            this.props.onChangeValue
                && this.props.onChangeValue(e.target.name, this.props.fromView ? this.props.fromView(e.target.value) : e.target.value);
            this.setState({value: e.target.value, error: null});
        }
        catch (ex) {
            this.setState({error: ex, value: e.target.value})
        }
    }

    componentDidUpdate() {
        if (this.ref)
            this.ref.style.height = this.ref.scrollHeight + "px";
    }

    componentDidMount() {
        if (this.ref)
            this.ref.style.height = this.ref.scrollHeight + "px";
    }

    render() {
        return <textarea
            className='MultilineInput'
            id={this.props.name}
            ref={r => this.ref = r}
            name={this.props.name}
            value={this.state.value}
            onChange={this._onChange}
            disabled={this.props.disabled}
            draggable={false}
        />;
    }
}

export
class CheckboxInput extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.any,
        onChangeValue: PropTypes.func.isRequired,
        fromView: PropTypes.func,
        toView: PropTypes.func,
        disabled: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.fromView = props.fromView || ((val) => isFinite(val) ? Boolean(Number(val)) : Boolean(val));
        this.toView = props.toView || ((val) => Boolean(val));
    }

    _onChange = (e) => {
        const val = this.fromView(e.target.checked);
        this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
    }

    render() {
        return <input
            type='checkbox'
            className='CheckboxInput'
            checked={this.toView(this.props.value)}
            id={this.props.name}
            name={this.props.name}
            onChange={this._onChange}
            disabled={this.props.disabled}
        />
    }
}


const lookupFromView = (val) => {
    if (Number(val) > 0)
        return Number(val);
    else if (Number(val) === -1)
        return null;
    else if (Number(val) === -2)
        return undefined;
    return val;
};

const lookupConvertToSpecial = (val) => {
    if (Number(val) === -1)
        return null;
    else if (Number(val) === -2)
        return undefined;
    return val;
}

const lookupConvertFromSpecial = (val) => {
    if (val === null)
        return -1;
    if (val === undefined)
        return -2;
    return val;
}

export
class LookupInput extends React.PureComponent {
    static propTypes = {
        items: PropTypes.array,
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
        onChangeValue: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        hasNull: PropTypes.bool,
        nullCaption: PropTypes.string,
        toView: PropTypes.func,
        fromView: PropTypes.func,
        hasUndefined: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.props = props || { fromView: lookupFromView };
    }

    _onChange = (e) => {
        let val = lookupConvertToSpecial(e.target.value);
        val = this.props.fromView && val ? this.props.fromView(val) : val;
        this.props.onChangeValue && this.props.onChangeValue(e.target.name, val);
    }

    _renderOptions = () => {
        const options = [];
        if (this.props.hasUndefined)
            options.push(<option key={'undefined'} value={-2}>{'<не определено>'}</option>);
        if (this.props.hasNull)
            options.push(<option key={'null'} value={-1}>{this.props.nullCaption || '<отсутствует>'}</option>);
        for (const item of this.props.items)
            options.push(<option key={item.value} value={item.value}>{item.caption}</option>);
        return options;
    }

    render() {
        const toView = this.props.toView || String;
        return <select
                    value={String(toView(lookupConvertFromSpecial(this.props.value)))}
                    id={this.props.name}
                    className={'Input'}
                    name={this.props.name}
                    onChangeCapture={this._onChange}
                    onChange={this._onChange}
                    disabled={this.props.disabled}
                >
                {this._renderOptions()}
        </select>
    }
}

export
const SectionLookupInput = React.memo((props) => {
    const ctx = useDataContext();
    const sections = ctx.sections;
    if (!sections || !sections[props.section])
        return <div className="InputValue">{`${props.section}<${props.value}>`}</div>

    const items = [...map(sections[props.section], ([k,v]) => ({
        value: v[props.keyField || 'id'],
        caption: props.titleField ? v[props.titleField] :
                 sectionInfo[props.section] && sectionInfo[props.section].lookupName ? sectionInfo[props.section].lookupName(v, ctx) :
                 v['name']
    }))];
    return <LookupInput {...props} items={items} />;
});


const coordsPattern=/^(-?\d{1,3}(\.\d{0,6})?);(-?\d{1,3}(\.\d{0,6})?)$/;
export
const CoordsInput = React.memo(({name, value, onChangeValue, disabled, item, icon, visibleMarker=true}) => {
    const {map} = useLeaflet();

    const onDragEnd = (e) => {
        onChangeValue(name, [_.round(e.target._latlng.lat,5), _.round(e.target._latlng.lng,5)])
    }

    const [internalValue, setInternalValue] = useState(null);
    const [error, setError] = useState(false);

    const marker = useRef();
    useEffect(() => {
        if (disabled && !value)
            return;

        const info = getObjSubtypeInfo(item);

        let iconMap
        if(disabled){
            iconMap = "arrows-alt"
        } else {
            if(info){
                if(info.icon) { 
                    iconMap = info.icon
                } else {
                    iconMap = 'map-marker-alt'
                }
            } else {
                iconMap = "bullseye"
            }
        }

        marker.current = visibleMarker && L.marker(value || map.getCenter(), {
            icon: icon || new DivIcon({
                html: ReactDOMServer.renderToStaticMarkup(
                    <div className="EditableMarker">
                        <FontAwesomeIcon 
                            className="icon" 
                            // icon={disabled ? (info ? info.icon : "bullseye") : "arrows-alt" || 'map-marker-alt'} 
                            icon={iconMap}
                        />
                    </div>
                ),
                className: 'legend-icon',
                iconAnchor: [10,30]
            }),
            draggable: !disabled
        }).on('dragend', onDragEnd).addTo(map);
        return () => {
            if (marker.current)
                map.removeLayer(marker.current);
        };
    }, [map, disabled, value])

    if (disabled)
        return <div className="InputValue">{value && value.join(";")}</div>

    const onChange = (e) => {
            if (e.target.value === "") {
                onChangeValue && onChangeValue(e.target.name, null);
                setInternalValue("");
                setError(false);
                return;
            }
            const res = coordsPattern.exec(e.target.value);
            if (!res) {
                setInternalValue(e.target.value);
                setError(true);
                return;
            }
            const lat = Number(res[1]);
            const lon = Number(res[3]);
            if (!lat || lat < -90 || lat > 90 || !lon || lon < -180 || lon > 180) {
                setError(true);
                return;
            }
            res && onChangeValue && onChangeValue(e.target.name, e.target.value.split(";").map(Number));
            setInternalValue(e.target.value);
            setError(false);
        }

    return <input
        className={"Input" + (error ? " error" : "")}
        type="text"
        name={name}
        value={internalValue || (value && value.join(";")) || ''}
        onChange={onChange}
    />
})

const CoordsArray = ({coords, onChangeValue, disabled}) => {
    const del = (index) => {
        let newItems = [...coords];
        newItems.splice(index,1);
        onChangeValue(newItems);
    }

    const onChange = (index, value) => {
        let newItems = [...coords];
        newItems[index] = value;
        onChangeValue(newItems);
    }

    const addItem = () => {
        let newItems = [...coords];
        newItems.splice(newItems.length-1, 0, null);
        while (newItems.length < 5)
            newItems.splice(newItems.length-1, 0, null);
        onChangeValue(newItems);
    }

    return <div className="Input InlineArray">
        {coords.slice(0,-1).map((c,n) => <div className="item" key={n}>
            <CoordsInput
                key={n}
                value={c}
                disabled={disabled}
                item={{}}
                visibleMarker={false}
                onChangeValue={(name, value) => onChange(n, value)}
            />
            {!disabled ? <button className="del" onClick={() => del(n)}><FontAwesomeIcon icon="trash" size="sm" /></button> : null}
        </div>)}
        {!disabled ? <button className="add" onClick={addItem}><FontAwesomeIcon icon="plus" size="sm" /></button> : null}
    </div>
}

export
const PolygonInput = React.memo(({name, value, onChangeValue, disabled, item}) => {
    const {map} = useLeaflet();

    const [internalValue, setInternalValue] = useState(value);
    const [editing, setEditing] = useState(false);

    const onDraw = useCallback((e) => {
        onChangeValue(name, e.layer.toGeoJSON().geometry);
        setEditing(false);
        setInternalValue(e.layer.toGeoJSON().geometry);
    }, [name, onChangeValue, setInternalValue])

    useEffect(() => {
        var poly;
        if (internalValue) {
            const geojson = {...internalValue};
            geojson.coordinates[0] = geojson.coordinates[0].filter(v => v);
            poly = L.geoJSON(geojson, {color: 'black', weight: '1', pane: 'decor'}).addTo(map);
        }

        var hdl;
        if (editing) {
            hdl = new L.Draw.Polygon(map, {
                icon: new L.DivIcon({
                    iconSize: new L.Point(8, 8)
                })
            });
            hdl.enable();
            map.on('draw:created', onDraw);
        }

        return () => {
            if (poly)
                map.removeLayer(poly);
            if (hdl)
                hdl.disable();
            map.off('draw:created', onDraw);
        };
    }, [map, disabled, value, editing, onDraw, internalValue])

    const edit = useCallback(e => {
        setInternalValue(null);
        setEditing(true);
    }, [setEditing]);

    const cancel = useCallback(e => {
        setInternalValue(value);
        setEditing(false);
    }, [value, setInternalValue, setEditing]);

    const onArrayChange = useCallback(value => {
        const newValue = [...value];
        if (newValue.length > 1)
            newValue[newValue.length-1] = newValue[0];
        const v = {...internalValue, coordinates: [value]}
        setInternalValue(v)
    }, [internalValue, setInternalValue]);

    if (disabled)
        return <CoordsArray
            coords={internalValue ? internalValue.coordinates[0] : []}
            disabled={disabled}
            onChangeValue={onArrayChange} />
        //return <div className="InputValue">{value ? "<на карте>" : "<не задано>"}</div>
    if (!editing)
        return <>
            <button onClick={edit}>Заменить/создать на карте</button>
            <CoordsArray
                coords={internalValue ? internalValue.coordinates[0] : []}
                disabled={disabled}
                onChangeValue={onArrayChange} />
        </>
    return <div className="Input">
        <button onClick={cancel}>Отменить</button>
        <CoordsArray
            coords={internalValue ? internalValue.coordinates[0] : []}
            disabled={disabled}
            onChangeValue={onArrayChange} />
    </div>
})

const ArrayItem = ({index, value, fields, disabled, onChange, del}) => {
    const controls = [];
    for (let field of fields) {
        if (field.hidden)
            continue;

        controls.push(<h4 key={field.name+"_title"}>{field.title}</h4>)
        controls.push(<Edit
            type={field.type}
            key={field.name}
            name={field.name}
            value={value && value[field.name]}
            onChangeValue={(field, value) => onChange(index, field, value)}
            disabled={disabled || field.readonly} />)
    }

    return <div className="item">
        {controls}
        {!disabled ? <button className="del" onClick={() => del(index)}><FontAwesomeIcon icon="trash" size="sm" /></button> : null}
    </div>
};

export
const ArrayInput = React.memo((props) => {
    const {meta} = useDataContext();
    const fields = meta.types[props.type];
    if (!fields)
        return <div>{`<unknown type ${props.type}>`}</div>;

    const onChange = (index, field, value) => {
        const newItems = [...props.value];
        newItems[index][field] = value;
        props.onChangeValue(props.name, newItems);
    }

    const del = (index) => {
        const newItems = [...props.value];
        newItems.splice(index,1);
        props.onChangeValue(props.name, newItems);
    }

    const addItem = () => {
        const newItem = {};
        for (let f of fields)
            newItem[f.name] = null;
        props.onChangeValue(props.name, [...(props.value || []), newItem]);
    }

    return <div className="Input ArrayInput">
        {props.value && props.value.map((v,i) => <ArrayItem
            key={i}
            index={i}
            value={props.value[i]}
            fields={fields}
            disabled={props.disabled}
            onChange={onChange}
            del={del}
        />)}
        {!props.disabled ? <button className="add" onClick={addItem}><FontAwesomeIcon icon="plus" size="sm" /></button> : null}
    </div>
}, (prev,next) => prev.item === next.item && prev.disabled === next.disabled)



const WasteItem = ({index, value, disabled, onChange, del}) => {
    return <div className="item">
            <Edit
                type="lookup section fkko"
                name="fkko_id"
                value={value&& value["fkko_id"]}
                onChangeValue={(field, value) => onChange(index, field, value)}
                disabled={disabled}
            />
            <Edit
                type="float"
                name="amount"
                value={value && value["amount"]}
                onChangeValue={(field, value) => onChange(index, field, value)}
                disabled={disabled}
            />
            {!disabled ? <button className="del" onClick={() => del(index)}><FontAwesomeIcon icon="trash" size="sm" /></button> : <div></div>}
        </div>
};


const WasteGenItemsInput = React.memo((props) => {
    const addItem = () => {
        const newItem = {fkko_id: null, amount: null};
        props.onChangeValue(props.name, [...(props.value || []), newItem]);
    }

    const onChange = (index, field, value) => {
        const newItems = [...props.value];
        newItems[index][field] = value;
        props.onChangeValue(props.name, newItems);
    }

    const del = (index) => {
        const newItems = [...props.value];
        newItems.splice(index,1);
        props.onChangeValue(props.name, newItems);
    }

    return <div className="Input WasteGenItemsInput">
            <div className="item">
                <h4>Вид отходов</h4>
                <h4>Количество</h4>
            </div>
            {props.value && props.value.map((v,i) => <WasteItem
                key={i}
                index={i}
                value={props.value[i]}
                disabled={props.disabled}
                onChange={onChange}
                del={del}
            />)}
            <div style={{textAlign: "center"}}>
                {!props.disabled ? <button className="add" onClick={addItem}>Добавить вид отходов</button> : null}
            </div>
        </div>
})


const WasteGenItem = ({index, value, disabled, onChange, del}) => {
    return <>
        <div className="item">
            <h4 key={"year_title"}>Год</h4>
            <Edit
                type="int"
                name="year"
                value={value && value["year"]}
                onChangeValue={(field, value) => onChange(index, field, value)}
                disabled={disabled}
            />
            {!disabled ? <button className="del" onClick={() => del(index)}><FontAwesomeIcon icon="trash" size="sm" /></button> : null}
        </div>
        <WasteGenItemsInput
            name="items"
            value={value && value["items"]}
            onChangeValue={(field, value) => onChange(index, field, value)}
            disabled={disabled}
        />
    </>
};


const WasteGenInput = React.memo((props) => {
    const addItem = () => {
        const newItem = {year: null, items: []};
        props.onChangeValue(props.name, [...(props.value || []), newItem]);
    }

    const onChange = (index, field, value) => {
        const newItems = [...props.value];
        newItems[index][field] = value;
        props.onChangeValue(props.name, newItems);
    }

    const del = (index) => {
        const newItems = [...props.value];
        newItems.splice(index,1);
        props.onChangeValue(props.name, newItems);
    }

    return <div className="Input ArrayInput">
        {props.value && props.value.map((v,i) => <WasteGenItem
            key={i}
            index={i}
            value={props.value[i]}
            disabled={props.disabled}
            onChange={onChange}
            del={del}
        />)}
        {!props.disabled ? <button className="add" onClick={addItem}><FontAwesomeIcon icon="plus" size="sm" /></button> : null}
    </div>
})



const LinkedInputItems = React.memo(({url, type, user, fields, disabled}) => {
    const [data, setData] = useState([]);

    const reload = useMemo(() => async () => {
        const res = (await apiRequest('GET', `${url}/${type}`))["data"];
        setData(res);
    }, [setData]);

    useEffect(() => {
        if (url.endsWith("new"))
            return;
        reload();
    }, [reload]);

    const section = url.split("/").slice(0,-1).join("/")+"//"+type;

    const Item = ({data, url, add}) => {
        const [changes, setChanges] = useState({});

        const onChange = (field, value) => {
            setChanges(changes => ({...changes, [field]: value}));
        };

        const del = async () => {
            await apiRequest('DELETE', `${url}`, changes, {'Authorization': `JWT ${user.token}`});
            reload();
        }

        const save = async () => {
            if (add) {
                (await apiRequest('POST', `${url}`, changes, {'Authorization': `JWT ${user.token}`}));
                setChanges({});
                await reload();
            }
            else {
                await apiRequest('PUT', `${url}`, changes, {'Authorization': `JWT ${user.token}`});
                setChanges({});
                await reload();
            }
        }

        return <div className="item">
            <ControlsList
                edit={!disabled && _.get(user, ['rights', section, add ? 'post' : 'put'])}
                meta={fields}
                values={{...data, ...changes}}
                onChange={onChange}
            />
            {(!disabled && !add && _.get(user, ['rights', section, 'delete'])) ? <button className="del" onClick={del}><FontAwesomeIcon icon="trash" size="sm" /></button> : null}
            {!disabled && !_.isEmpty(changes) ? <button className="save" onClick={save}><FontAwesomeIcon icon="save" size="sm" /></button> : null}
        </div>
    };

    if (url.endsWith("new"))
        return <div>Сохраните запись для редактирования связанных сущностей</div>;

    return <div className="Input LinkedInput">
        {data && data.map(p => <Item key={p.id} data={p} url={`${url}/${type}/${p.id}`}/>)}
        {!disabled && _.get(user, ['rights', section, 'post']) ? <Item key={"new"} add={true} data={{}} url={`${url}/${type}`}/> : null}
    </div>
})

export
const LinkedInput = React.memo(({type, disabled}) => {
    const {meta, user} = useDataContext();
    const location = useLocation();
    const fields = meta.types[type];

    return <LinkedInputItems url={location.pathname} type={type} user={user} fields={fields} disabled={disabled} />;
})

export
const RecordInput = React.memo((props) => {
    const {meta} = useDataContext();
    const fields = meta.types[props.type];

    const onChange = useCallback((field, value) => {
      const newValue = {...props.value};
      newValue[field] = value;
      props.onChangeValue(props.name, newValue);
    }, [props]);

    if (!fields)
        return <div>{`<unknown type ${props.type}>`}</div>;

    const fillValue = () => {
        const newValue = {};
        for (let f of fields)
            newValue[f.name] = null;
        return newValue;
    }

    const value = props.value || fillValue();

    const controls = [];
    for (let field of fields) {
        if (field.hidden)
            continue;

        controls.push(<h4 key={field.name+"_title"}>{field.title}</h4>)
        controls.push(<Edit
            type={field.type}
            key={props.pkey+"_"+field.name}
            name={field.name}
            value={value[field.name]}
            onChangeValue={onChange}
            disabled={props.disabled || field.readonly} />)
    }

    return <div className="Input RecordInput">
        {controls}
    </div>
})


export
const WasteLegControl = React.memo((props) => {
    const {user} = useDataContext();
    const [calc, setCalc] = useState(false);
    const [value, setValue] = useState(props.value);

    const update = useCallback(() => {
        const fn = async () => {
            setCalc(true);
            const res = (await apiRequest('GET', `/waste_traffic/${props.item.id}/calc_avg_leg`, undefined, {'Authorization': `JWT ${user.token}`}));
            if (res.result)
                setValue(res.avg_leg);
            setCalc(false); 
        }
        fn();
    }, [setCalc, setValue, props]);

    const enabled = props.editing && !calc && props.item.id && _.get(user, ['rights', '/waste_traffic/calc_avg_leg', 'post']);

    var displayValue = '-';
    if (calc)
        displayValue = '(расчёт)'
    else if (value)
        displayValue = value.toFixed(1);

    return (
        <div className="Input" style={{display: 'flex'}}>
            <div>{displayValue}</div>
            {enabled ?
                <button className="update" onClick={update}><FontAwesomeIcon icon="sync-alt" size="sm" /></button>
            : null}
        </div>
    );
});

const
inputMapping = {
    'string': TextInput,
    'text': MultilineInput,
    'int': NumberInput,
    'float': NumberInput,
    'boolean': CheckboxInput,
    'bool': CheckboxInput,
    'date': DateInput,
    'coords': CoordsInput,
    'waste_leg': WasteLegControl,
    'polygon': PolygonInput
}

const Edit = React.memo((props) => {
    const {enums} = useDataContext();

    if (!props.type)
        return null;

    const type = props.type.split(" ");
    if (inputMapping[type[0]])
        {
            const Input = inputMapping[type[0]];
            return <Input {...props} />;
        }

    if (type[0] === "lookup") {
        if (type[1] === "enum") {
                const stringKeys = _.filter(enums[type[2]], (v,k) => isNaN(Number(k))).length > 0;
                const items = _.map(enums[type[2]], (v,k) => ({value: k, caption: v}));
                return <LookupInput {...props} items={items}
                    hasNull={true} hasUndefined={true}
                    toView={stringKeys ? String : Number} fromView={stringKeys ? String : Number} />;
            }
        if (type[1] === "section")
            return <SectionLookupInput hasNull={true} hasUndefined={true}
                toView={Number} fromView={Number} {...props} section={type[2]}/>;

    }

    if (type[0] === "array") {
        return <ArrayInput {...props} key={props.pkey} type={type[1]} />
    }

    if (type[0] === "record") {
        return <RecordInput {...props} key={props.pkey} type={type[1]} />
    }

    if (type[0] === "linked") {
        return <LinkedInput {...props} key={props.pkey} type={type[1]} />
    }

    if (type[0] === "waste_gen") {
        return <WasteGenInput {...props} key={props.pkey} />
    }
});

export
const ControlsList = React.memo(({edit, onChange, meta, values}) => {
    const controls = [];
    for (let field of meta) {
        if (field.hidden || !field.type)
            continue;

        if (field.type.startsWith("array")
            || field.type.startsWith("record")
            || field.type.startsWith("linked")
            || field.type === "waste_gen") {
            controls.push(<div key={field.name+"_embed"} className="embedded">
                <h3 key={field.name+"_title"}>{field.title}</h3>
                <Edit
                    type={field.type}
                    key={field.name}
                    name={field.name}
                    value={values[field.name]}
                    item={values}
                    onChangeValue={onChange}
                    editing={edit}
                    disabled={!edit || field.readonly} />
            </div>);
        }
        else {
            controls.push(<h4 key={field.name+"_title"}>{field.title}</h4>)
            controls.push(<Edit
                type={field.type}
                key={field.name}
                name={field.name}
                value={values[field.name]}
                item={values}
                onChangeValue={onChange}
                editing={edit}
                disabled={!edit || field.readonly} />)
        }
    }

    return <div className="ControlsList">
        {controls}
    </div>
}, (prev,next) => {
    return prev.values === next.values && prev.edit === next.edit && prev.onChange === next.onChange && prev.meta === next.meta;
});
