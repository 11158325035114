import React, { 
    useMemo, 
    useState, 
    useCallback 
} from 'react';
import { apiRequest } from '../api';
import { useDataContext } from '../DataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { 
    sectionInfo, 
    mapObjects 
} from '../metadata';
import { Link } from 'react-router-dom';
import L from 'leaflet';
import { 
    makePointIcon, 
    MarkerClusterGroup, 
    urlQueryToArray 
} from '../util';
import {
    ControlsList, 
    NumberInput 
} from '../edits/SimpleEdit';
import ImportControl from '../edits/ImportControl';

const ITEMS_PER_PAGE = 100;

function ListPaneItem({obj, idx, section, history}) {
    const ctx = useDataContext();
    const {user, reloadSection} = ctx;
    const info = sectionInfo[section];

    const del = async () => {
        await apiRequest('DELETE', `/${info.apiSection || section}/${obj.id}`, {}, {'Authorization': `JWT ${user.token}`});
        if (info.apiSection)
            reloadSection(info.apiSection);
        else
            reloadSection(section);
    }

    return(
        <div className="ListPaneItem">
            <Link className="name" to={`/${section}/${obj.id}`}>
                {info.listName ? <div>{idx}) {info.listName(obj, ctx)}</div>
                :  (info.listFields || ['name'] ).map(v => <div key={v}>{idx}) {obj[v]}</div>)}
            </Link>
            <div className="controls">
                <button className="view" onClick={() => history.push(`/${section}/${obj.id}`)}>
                    <FontAwesomeIcon icon="eye" size="sm" />
                </button>
                {
                    user ? (
                        <button className="del" onClick={del}>
                            <FontAwesomeIcon icon="trash" size="sm" />
                        </button>
                    ) : null
                }
            </div>
        </div>
    )
}

function ListPage({data, page, section, history}) {
    const ctx = useDataContext();
    // после авторизации перезапросить данные по пользователям
    if(section === 'users'){
        if(data.length <= 0){
            // запрос
            ctx.afterLoadUsers()
        }
    }
    data = data.slice(ITEMS_PER_PAGE*(page-1), ITEMS_PER_PAGE*page);
    return(
        <>
            {
                data.map((p, idx) => {
                    return(
                        <ListPaneItem 
                            key={p.id} 
                            obj={p} 
                            idx={idx+ITEMS_PER_PAGE*(page-1)+1} 
                            section={section} 
                            history={history} 
                        />
                    )
                })
            }
        </>
    )
}

function PageControl({page, totalPages, history}) {
    const setPage = (page) => {
        if (Number(page) < 1 || Number(page) > totalPages || page === null || page === undefined)
            return;

        const params = new URLSearchParams(history.location.search);
        params.set("page", page);
        history.replace(history.location.pathname + "?" + params.toString());
    }

    return(
        <div className="PageControl">
            <button disabled={page <= 1} onClick={() => setPage(Number(page)-1)}>{"<"}</button>
            <NumberInput value={page} min={1} max={totalPages} onChangeValue={(name, value) => setPage(value)} />
            <button disabled={page >= totalPages} onClick={() => setPage(Number(page)+1)}>{">"}</button>
        </div>
    )
}

export default function ListPane({location, history, section}) {
    const ctx = useDataContext();
    const {user, sections, loading, meta} = ctx;
    const [filterValues, setFilterValues] = useState({});
    const info = sectionInfo[section];
    const [LPSearch, setLPSearch] = useState(null);
    const [LPSearchStatus, setLPSearchStatus] = useState(false);

    const SearchAlgorithm = (p) => {
        if(p.address !== null || p.name !== null ){
            if (p.address) p.addressLC = p.address.toString().toLowerCase();
            if (p.name) p.nameLC = p.name.toString().toLowerCase();
            if (p.id) p.idLC = p.id.toString();
            let pod = (p.nameLC || p.addressLC || []).includes(LPSearch.toString().toLowerCase());
            if (pod <= 0) pod = (p.idLC ||  []).includes(LPSearch.toString().toLowerCase());
            return pod;
        }
    }

    const ListPaneSearch = () => {

        const onChange = (e) => {
            setLPSearch(e.target.value);
            setLPSearchStatus(true);
            e.target.value === '' && setLPSearchStatus(false);
        }

        const onClick = () => {
            setLPSearchStatus(false);
            setLPSearch(null);
        }

        return (
            <div>
                <div className="ListPaneSearch">
                    <input
                        autoFocus='autofocus'
                        placeholder='Поиск'
                        type='text'
                        onChange={onChange}
                        value={LPSearch ? LPSearch : ''}
                    />
                    <button onClick={onClick}>Сбросить</button>
                </div>
            </div>
        );
    }

    const data = useMemo(() => {
        if (loading || _.isEmpty(sections))
            return null;

        const query = urlQueryToArray(location.search);
        delete query.page;
        let res = [];
        // if (!_.isEmpty(query))
        if (Object.keys(query).lentgh > 0){
            res = _.filter([...(sections[info.parentSection || section]).values()], query);
        } else if (info.parentSection){
            res = info.filter([...sections[info.parentSection].values()]);
        } else {
            // res = [...sections[section].values()];
            res = sections[section] ? [...sections[section].values()] : []
        }
        return _.filter(res, _.pickBy(filterValues, v => v !== undefined));
    }, [loading, info, sections, section, location.search, filterValues]);


    const params = new URLSearchParams(location.search);
    let subtypeInfo = {};
    if (params.has('source_type'))
        subtypeInfo = mapObjects.sources.subtypesInfo[params.get('source_type')];
    if (params.has('dump_type'))
        subtypeInfo = mapObjects.dumpPoints.subtypesInfo[params.get('dump_type')];
    if (params.has('facility_type'))
        subtypeInfo = mapObjects.facilities.subtypesInfo[params.get('facility_type')];

    const markers = useMemo(() => {
        let res = _.filter(data, 'lat_lon');
        return res.map(p =>
            L.marker(p.lat_lon, {icon: makePointIcon(subtypeInfo.icon)})
                .bindTooltip(`<div class="marker-tooltip">${p.name || p.address}</div>`)
                .on('click', () => history.push(`${subtypeInfo.url}/${p.id}`))
            );
    }, [data]);

    const filterMeta = useMemo(() => {
        if (!meta.sections || !meta.sections[section])
            return [];

        const res = [];
        if (info.search && info.search.length !== 0) {
            for (const f of meta.sections[section]) {
                if (info.search.find(v => v === f.name))
                    res.push(f);
            }
        }
        return res;
    }, [meta, info, section]);

    const onFilterValueChange = useCallback((name, val) => {
        setFilterValues(fv => ({...fv, [name]: val}));
    }, [setFilterValues]);

    const listData = useMemo(() => {
        if (!LPSearchStatus)
            return data || [];
        return (data || []).filter(SearchAlgorithm);
    }, [data, SearchAlgorithm])

    const totalPages = Math.ceil(listData.length / ITEMS_PER_PAGE);

    return(
        <div className="ListPane">
            <div className="head">
                <h2>{info.name}</h2>
                <div className="controls">
                    <button className="close" onClick={history.goBack}>
                        <FontAwesomeIcon icon="times" size="lg" />
                    </button>
                </div>
            </div>
            {
                loading ? <div>Загрузка данных</div> : null
            }
            {
                info.search ? (
                    <ControlsList 
                        edit={true} 
                        meta={filterMeta} 
                        values={filterValues} 
                        onChange={onFilterValueChange} 
                    />
                ) : null
            }
            {
                !loading && user && _.get(user, ['rights', '/'+section, 'post']) ? (
                    <button className="add" onClick={() => history.push(`/${section}/new${location.search}`)}>
                        <FontAwesomeIcon icon="plus" />
                    </button>
                ) : null
            }
            {
                !loading && info.report ? (
                        <button  onClick={() => window.open(info.report)}>
                            <FontAwesomeIcon icon="file-download" />
                        </button>
                    ) : null
            }
            <ImportControl section={section} />
            <ListPaneSearch />
            <ListPage 
                data={listData} 
                page={(params.get('page') || 1)} 
                section={section} history={history}
            />
            {
                totalPages > 1 ? (
                    <PageControl 
                        page={(params.get('page') || 1)} 
                        totalPages={totalPages} 
                        history={history} 
                    />
                ) : null
            }
            <MarkerClusterGroup 
                markers={markers} 
                showCoverageOnHover={false} 
                maxClusterRadius={40} 
            />
        </div>
    )
}