import React, {
    useState, 
    // useEffect, 
    useMemo, 
    // useRef
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useHistory, Link} from 'react-router-dom';
import { useLeaflet } from 'react-leaflet';
import _ from 'lodash';
import L from 'leaflet';
import { useDataContext } from '../DataProvider';
import { makePointIcon, MapLayer, MarkerClusterGroup, map as fmap, filter } from '../util';
import { mapObjects, getObjSubtypeInfo } from '../metadata';


function MapObjectSubtype({obj, iconName, visible, toggle, region}) {
    const {user} = useDataContext();
    const qty = obj.qty && obj.qty.count;
    return <div className="MapObjectSubtype">
        <div
            className={"icon" + (visible ? " visible" : "")}
            onClick={toggle}
        >
            {iconName && <FontAwesomeIcon icon={iconName} />}
        </div>
        <div className="name">{obj.name}</div>
        <div className="qty">{qty}</div>
        <div className="buttons">
            <Link className="list" to={`${obj.info.url}/list?${obj.subtypeKey}=${obj.subtypeId}` + (region ? `&admin_id=${region.id}` : '')}><FontAwesomeIcon icon="list" /></Link>
            {obj.info && _.get(user, ['rights', obj.info.url, 'post']) &&
                <Link className="add" to={`${obj.info.url}/new?${obj.subtypeKey}=${obj.subtypeId}` + (region ? `&admin_id=${region.id}` : '')}><FontAwesomeIcon icon="plus" /></Link>}
        </div>
    </div>
}


function MapObjectType({obj, setVisibility, region}) {
    return <div className="MapObjectType">
        <h3 className="name">{obj.name}</h3>
        <div className="subtypes">
            {obj.subtypesInfo ?
                _.map(obj.subtypesInfo, (v,k) =>
                    <MapObjectSubtype
                        key={k}
                        obj={{
                            name: v.listName || v.name,
                            qty: _.find(obj.info, [obj.infoKey, Number(k)]),
                            info: obj.subtypesInfo && obj.subtypesInfo[k],
                            subtypeId: k,
                            subtypeKey: obj.infoKey
                        }}
                        iconName={obj.subtypesInfo && obj.subtypesInfo[k].icon}
                        visible={obj.visibleSubtypes.has(Number(k))}
                        toggle={() => setVisibility(Number(k), !obj.visibleSubtypes.has(Number(k)))}
                        region={region}
                    />)
            : null}
        </div>
    </div>
}

let visibleObjectsContext = null;


const ObjectsPane = React.memo(({region}) => {
    const {sections, getAtdItem} = useDataContext();
    const [visibleObjects, setVisibleObjects] = useState(visibleObjectsContext);
    const history = useHistory();
    const {map} = useLeaflet();

    visibleObjectsContext = visibleObjects;
    if (!visibleObjectsContext) {
        const vis = {};
        for (let k in mapObjects) {
            vis[k] = new Set();
        }
        setVisibleObjects(vis);
    }

    const setVisibility = (type, subtype, visibility) => {
        setVisibleObjects(vis => {
            if (visibility)
                vis[type].add(subtype);
            else
                vis[type].delete(subtype);
            const res = Object.assign({}, vis);
            return res;
        })
    }

    const objects = _.map(mapObjects, (v,k) => ({
        ...v,
        info: region && region[v.infoMap],
        key: k,
        visibleSubtypes: visibleObjects && visibleObjects[k]}));

    const h = [];
    if (region) {
        h.unshift(<h4 key="1">{region.name}</h4>);
        let parent = getAtdItem(region.parent_id);
        if (parent) {
            h.unshift(<h4 key="2">{parent.name}</h4>);
            parent = getAtdItem(parent.parent_id);
            if (parent)
                h.unshift(<h4 key="3">{parent.name}</h4>);
        }
    }

    const sources = useMemo(() => new Map([...sections['sources/organizational'] || [],  ...sections['sources/residential'] || []]),
        [sections['sources/organizational'], sections['sources/residential']]);
    const dumpPoints = sections.dump_points;
    const facilities = sections.facilities;
    const traffic = sections.waste_traffic;

    const sourcesMarkers = useMemo(() => {
        if (!sources || !visibleObjects)
            return [];
        let res = filter(sources, ([k,v]) => v.lat_lon);
        res = filter(res, ([k,v]) => visibleObjects['sources'].has(v.source_type));
        res = [...fmap(res, ([k,p]) =>
            L.marker(p.lat_lon, {icon: makePointIcon(getObjSubtypeInfo(p).icon)})
                .bindTooltip(`<div class="marker-tooltip">${p.address}</div>`)
                .on('click', () => history.push(`${getObjSubtypeInfo(p).url}/${p.id}`))
            )];
        return res;
    }, [sources, visibleObjects]);

    const dumpPointsMarkers = useMemo(() => {
        if (!dumpPoints || !visibleObjects)
            return [];
        let res = filter(dumpPoints, ([k,v]) => v.lat_lon);
        res = filter(res, ([k,v]) => visibleObjects['dumpPoints'].has(v.dump_type));
        return [...fmap(res, ([k,p]) =>
            L.marker(p.lat_lon, {icon: makePointIcon(getObjSubtypeInfo(p).icon)})
                .bindTooltip(`<div class="marker-tooltip">${p.name}</div>`)
                .on('click', () => history.push(`${getObjSubtypeInfo(p).url}/${p.id}`))
            )];
    }, [dumpPoints, visibleObjects]);

    const facilitiesMarkers = useMemo(() => {
        if (!facilities || !visibleObjects)
            return [];
        let res = filter(facilities, ([k,v]) => v.lat_lon);
        res = filter(res, ([k,v]) => visibleObjects['facilities'].has(v.facility_type));
        return [...fmap(res, ([k,p]) =>
            L.marker(p.lat_lon, {icon: makePointIcon(getObjSubtypeInfo(p).icon)})
                .bindTooltip(`<div class="marker-tooltip">${p.name}</div>`)
                .on('click', () => history.push(`${getObjSubtypeInfo(p).url}/${p.id}`))
            )];
    }, [facilities, visibleObjects]);

    const markers = useMemo(() => [...sourcesMarkers, ...dumpPointsMarkers], [sourcesMarkers, dumpPointsMarkers]);

    const trafficLines = useMemo(() => {
        const res = [];
        if (!traffic || !visibleObjects)
            return res;
        for (let [,t] of traffic) {
            const from = (t.from_admin_id && sections.admin_boundaries.get(t.from_admin_id))
                        || (t.from_agg_source_id && sources.get(t.from_agg_source_id))
                        || (t.from_facility_id && facilities.get(t.from_facility_id));

            if (!from || (from.facility_type !== undefined && !visibleObjects['facilities'].has(from.facility_type)))
                continue;

            const to = t.to_facility_id && facilities.get(t.to_facility_id);
            if (!to || !visibleObjects['facilities'].has(to.facility_type))
                continue;

            if (from.lat_lon && to.lat_lon)
                res.push(L.polyline([from.lat_lon, to.lat_lon], {
                    className: "traffic-line"
                }));
        }
        return res;
    }, [traffic, sections, visibleObjects]);

    const sourceTrafficLines = useMemo(() => {
        if (!visibleObjects)
            return [];

        const lines = new Set();
        const res = [];

        for (const sid in sources) {
            const s = sources[sid];
            if (!s.lat_lon || !s.admin_center || !visibleObjects['sources'].has(s.source_type))
                continue;

            const from = map.latLngToLayerPoint(L.latLng(s.lat_lon));
            const to = map.latLngToLayerPoint(L.latLng(s.admin_center));
            const ln = String([from.x,from.y,to.x,to.y]);
            if (lines.has(ln))
                continue;

            lines.add(ln);
            res.push(L.polyline([s.lat_lon, s.admin_center], {
                className: "traffic-line"
            }))
        }
        return res;
    }, [sources, visibleObjects])


    return <div className="ObjectsPane">
            <div className="MapControlsPane">
                {/* <div>
                    Показывать: <select onChange={(e) => setAtdLevel(e.target.value)} value={atdLevel}>
                        <option value={6}>Районы</option>
                        <option value={8}>Муниципальные образования</option>
                    </select>
                </div> */}
            </div>
            <h2>Объекты карты</h2>
            <div className="types">
                {objects.map(v => <MapObjectType
                    key={v.url}
                    obj={v}
                    setVisibility={(subtype, show) => {
                        // console.log('****')
                        setVisibility(v.key, subtype, show)
                    }}
                    region={region}
                />)}
            </div>
            <MarkerClusterGroup markers={markers} showCoverageOnHover={false} maxClusterRadius={40} />
            {region && <MapLayer items={[L.geoJSON(region.geometry, {
                style: {
                    weight: 2,
                    color: 'red',
                    fillColor: 'transparent'
                },
                pane: 'decor'
            })]} />}
            <MapLayer items={trafficLines} />
            <MapLayer items={sourceTrafficLines} />
            <MapLayer items={facilitiesMarkers} />
    </div>
}, (prev, next) => prev.region === next.region);


export default ObjectsPane;